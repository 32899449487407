import React from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

import Layout from "../components/layout"
import SEO from "../components/seo"
import ImageGalleryWithLightbox from "../components/imageGalleryWithLightbox"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.mdx
  const postImages = data.postImages
  const headerImage = post.frontmatter.featuredImage?.childImageSharp?.fluid // postImages?.edges[0].node.childImageSharp.fluid
  // const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data

  const shortcodes = {SimpleReactLightbox,SRLWrapper,ImageGalleryWithLightbox}

  return (
    <Layout location={location} title={post.frontmatter.title} slogan={post.frontmatter.date} headerImage={headerImage}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        {/* <header>
          <h1 itemProp="headline">{post.frontmatter.title}</h1>
          <p>{post.frontmatter.date}</p>
        </header> */}
        <MDXProvider components={shortcodes}><MDXRenderer postImages={postImages}>{post.body}</MDXRenderer></MDXProvider>
        
      </article>
      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          {previous && (
          <li className="nextPrevPostButton">
              <Link to={"/tagebuch"+previous.fields.slug} rel="prev">
               ← {previous.frontmatter.title}
              </Link>
          </li>
          )}
          {next && (
          <li className="nextPrevPostButton">
              <Link to={"/tagebuch"+next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
          </li>
          )}
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
    $imageFolder: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "LL", locale: "de")
        description
        featuredImage {
          childImageSharp {
              fluid(quality: 90, maxWidth: 680) {
                ...GatsbyImageSharpFluid_withWebp
              }
          }
        }
      }
    }
    previous: mdx(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: mdx(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    postImages: allFile(filter: {absolutePath: {regex: $imageFolder}}) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
